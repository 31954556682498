<template>
  <div>
    <div class="toolbar">
      Don't have an account?&nbsp;
      <v-btn small dark color="primary" to="/register">
        Register (for Free)
      </v-btn>
    </div>
    <v-form
    ref="form"
    v-model="valid"
    lazy-validation>
      <v-card elevation="0" class="mx-10 px-10" color="transparent">
        <v-card-text>
          <a href="/" plain text inline>
            <v-logo :show-big="true" width="200px"></v-logo>
          </a>
          <h2 class="text-h4 text-center">Account Sign-In</h2>
        </v-card-text>
        <v-card-text>
          <v-container fluid v-if="loading" class="mb-4">
            <v-row dense>
              <v-col class="mx-auto text-center">
                <v-progress-circular
                  :size="70"
                  :width="7"
                  color="primary"
                  indeterminate
                ></v-progress-circular>
              </v-col>
            </v-row>
          </v-container>

          <v-container fluid v-else>
            <v-row dense>
              <v-col>
                <v-text-field
                  background-color="rgba(255, 255, 255, 0.75)"
                  outlined
                  type="email"
                  v-model="fields.email.value"
                  :rules="[$utilities.rules.required, $utilities.rules.isEmail]"
                  :label="fields.email.label"
                  :append-icon="fields.remember.value ? 'mdi-checkbox-outline' : 'mdi-checkbox-blank-outline'"
                  @click:append="fields.remember.value = !fields.remember.value"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col class="text-end">
                <v-text-field
                  background-color="rgba(255, 255, 255, 0.75)"
                  outlined
                  type="password"
                  v-model="fields.password.value"
                  :rules="[$utilities.rules.required]"
                  :label="fields.password.label"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <v-btn color="primary" x-large dark @click="login">
                  Login
                </v-btn>
              </v-col>
              <v-col cols="4" align-self="center" class="text-end">
                <a href="#" @click="forgotDialog = true">
                  Forgot Password?
                </a>
              </v-col>
            </v-row>
            <v-row align="center" class="my-3">
              <v-col cols="5" class="text-center">
                <v-divider />
              </v-col>
              <v-col cols="2" class="text-center"> OR </v-col>
              <v-col cols="5" class="text-center">
                <v-divider />
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="6" offset="3" align="center">
                <div id="google-button"></div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>  
    </v-form>
    <v-modal-users-forgot v-model="forgotDialog"></v-modal-users-forgot>
  </div>
</template>

<script>
  import { mapActions } from 'vuex';
  import { loadScript } from "vue-plugin-load-script";
  
  export default {
    name: 'Home.vue',
    data() {
      return {
        forgotDialog: false,
        loading: false,
        valid: false,
        fields: {
          email: {
            value: '',
            label: 'Email',
          },
          password: {
            value: '',
            label: 'Password',
          },
          remember: {
            value: false,
            label: 'Remember me',
          }
        },
      }
    },
    computed:{
      siteURL(){
        return process.env.VUE_APP_ENDPOINT;
      },
      googleClientID(){
        return process.env.VUE_APP_GOOGLE_CLIENT_ID;
      }
    },
    created(){
      let self = this;
      const storedEmail = this.$utilities.getCookie('userEmail');
      if (storedEmail) {
        this.fields.email.value = decodeURIComponent(storedEmail);
        this.fields.remember.value = true;
      }
      loadScript("https://accounts.google.com/gsi/client")
        .then(() => {
          if (!google) return; // eslint-disable-line
          google.accounts.id.initialize({ // eslint-disable-line
            client_id: process.env.VUE_APP_GOOGLE_CLIENT_ID,
            callback: self.oauth
          });
          google.accounts.id.renderButton( // eslint-disable-line
            document.getElementById("google-button"),
            { theme: "outline", size: "large" }
          );
          google.accounts.id.prompt(); // eslint-disable-line
        })
        .catch(() => {
          document.getElementById("google-button").innerHTML = '<b>Please disable your Ad Blocker to enable Google Login!</b>'
        })
    },
    methods:{
      saveEmailToCookie() {
        const set = this.fields.remember.value;
        const email = this.fields.email.value;
        if (set && email) {
          this.$utilities.setCookie('userEmail', email, 30);
        }else{
          this.$utilities.deleteCookie('userEmail');
        }
      },
      ...mapActions({
        'userLogin':'users/login',
        'userOAuth':'users/oauth',
      }),
      oauth(response){
        this.loading = true;
        let self = this;
        let fields = {
          'credential': response.credential
        }
        this.userOAuth(fields)
          .then(() => {
            self.$router.push('/')
              .then(() => {
                self.systemMessage('Google account logged in successfully!');
                self.loading = false;
              })
          })
          .catch(() => {
            self.systemMessage('Account not found!')
            self.loading = false;
          })
      },
      login(){
        let self = this;
        if(this.$refs.form.validate()){
          this.loading = true;
          let fields = {
						'email': self.fields.email.value,
						'password': self.fields.password.value,
					}
          this.userLogin(fields)
						.then((response) => {
              if(!response.length > 0){
                setTimeout(() => {
                  self.$router.push('/')
                    .then(() => {
                      self.loading = false;
                    });
                }, 2000);
              }
            })
            .catch(() => {
              self.loading = false;
            })
        }
      },
    },
    watch:{
      'fields.remember.value': function(){
        this.saveEmailToCookie();
      }
    }
  }
</script>

<style lang="scss">
.login{
  .toolbar{
    position: absolute;
    top: 20px;
    right: 20px;
  }
}
</style>